import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeaderComponent from "../components/page-header";
import BgImage from "../images/pages/careers.jpg"
import ImgCallCenter from "../images/misc/bg-callcenter.jpg"
import { Container } from "react-bootstrap";

const CareersPage = () => (
    <Layout >
        <div style={{
            backgroundImage: `url(${BgImage})` ,
            backgroundPosition:`center center`,
            backgroundSize:`cover`
        }}>


        <PageHeaderComponent name="Join our team" desc="At Smart Solution, you will work with talented people to shape the future of Customer Care services. "/>
        </div>
        <SEO title="Careers" />
        <section className="page-section">
            <Container>
                <div className="d-flex mb-5">

                    <div>
                        <h2 className="service-heading">Sorry, there are no positions available at this time</h2>
                        <p className="secondary-font text-muted mb-3">Thank you for your inquiry about employment opportunities at Smart Solution Group LLC.</p>
                        <p className="secondary-font text-muted mb-0">
                            Currently we don't have any open positions but if you are interested in possible future opportunities please send your CV to info@smartsolution-llc.com.</p>
                    </div>
                </div>


            </Container>
        </section>
    </Layout>
)

export default CareersPage
